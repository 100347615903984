import React from "react"
// import Toggler from './toggler'
// import './alumni.scss'
import style from './alumni.module.scss'


export default ({ employees }) => (

  <div className={style.alumni}>
    <h3>Ehemalige<br /><br /></h3>
    <div className={style.alumni__wrapper}>
      {
        employees.map((item, i) => {
            
            return(
              <div key={i} className={style.item}>
                <div>{item}</div>
              </div>
            )

          })
        }
    </div>
  </div>

)
