import React from "react"

// import './buero.scss'
import style from './buero.module.scss'
import Footer from "../../layout/footer"
import ImageHandler from '../../imageHandler/imageHandler'
import shuffle from '../../../helpers/shuffle'

import Employees from './employees'
import About from './about'
import Werkliste from './werkliste'


export default ({ buero, employeesShuffled }) => {
  let imagesTop    = []
  let imagesBottom = []

  const footer = {
    imprint: buero.imprint,
    copyright: buero.copyright,
    liability: buero.liability,
  }

  const images = shuffle(buero.images);
  images.map((image, i) => {
    image.key = i
    return image
  })


  images.forEach((image, i) => { 
    if(i < images.length/2) imagesTop.push(image)
    else imagesBottom.push(image)
  })

  const werkliste = buero.werkliste && buero.werkliste.mediaItemUrl ? buero.werkliste.mediaItemUrl : false

  const alumni = buero.alumni.replace(/\r|\n/ig, '').split(/<br\s?\/>/ig)

  return (
    <>
      <div className={`page ${style.buero}`}>
        <div className={style.images} >
            <ImageHandler images={imagesTop} row={0}/>
            <ImageHandler images={imagesBottom} row={1}/>
        </div>
            
        <div className={style.wrapper}>
            <div className={style.section}>
              <div className={style.aboutDesktop}>Mitarbeiter</div>
              <div className={`${style.section} ${style.employees}`}> 
                <Employees title="Mitarbeiter" employees={employeesShuffled} alumni={alumni}/>
              </div>
            </div>
            
            <div className={`${style.section} ${style.about}`}>

              <div className={style.aboutDesktop}>Über Uns</div>
              <div className={style.werklisteWrapper}>
                {werkliste ? 
                    <div className={`${style.section__button} ${style.werkliste}`} >
                      <a href={werkliste} download={true}>
                          <div>Werkliste</div>
                          <Werkliste />
                      </a>
                    </div> 
                : false }
              </div>

              <About title="Über uns" about_us={buero.aboutUs} jobs={buero.jobs} biography={buero.biography} />
            </div>
        </div>  

        <Footer footer={footer} />
        </div>  
    </>
  )
}

