import React from "react"
import Toggler from './toggler'
import Alumni from './alumni'
import style from './buero.module.scss'

const Employee = ({ item, style }) => (
  <a href={`mailto:${item.email}`}>
    <div className={style.large}>{`${item.firstName} ${item.name}`}</div>
    <div>{item.title}</div>
    <div>{item.position}</div>
  </a>
)

export default ({ title, employees, alumni }) => (
  <Toggler title={title}>
    {
      employees.map((item, i) => {

          return(
            <div key={i} className={style.item}>
              <Employee key={i} style={style} item={item} />
            </div>
          )
      })
    }
    <Alumni employees={alumni} />
  </Toggler>
)