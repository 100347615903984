
import React, { useState } from "react"
// import "./footer.scss"
import style from "./footer.module.scss"

// class Footer extends React.Component {

export default ({ footer }) => {

    const handleFooter = (e) => {
        setFooter(!isOpenFooter)
    }

    const [ isOpenFooter, setFooter ] = useState(0);

    // render(){
    return(
        <div className={`${style.footer} ${isOpenFooter ? style.open : ""}`}>
            <div>
                <div className={style.footer__item}>
                    <div className={style.footer__button}
                    onClick={handleFooter} 
                    onKeyDown={handleFooter} 
                    role="button" 
                    tabIndex={0} 
                    >
                        <h3 dangerouslySetInnerHTML={{__html: "Impressum" }}/>
                    </div> 
                    <div>{
                        footer.imprint.map((item, i) => {
                            return(
                                <div key={i}>
                                    <p dangerouslySetInnerHTML={{__html: `${item.label}: ${item.text}` }} />
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={style.footer__item}>
                    <h3 dangerouslySetInnerHTML={{__html: "Urheberrecht" }} />
                    <p dangerouslySetInnerHTML={{__html: footer.copyright }} />
                </div>
                <div className={style.footer__item}>
                <h3 dangerouslySetInnerHTML={{__html: "Haftungsnachweis" }} />
                <p dangerouslySetInnerHTML={{__html: footer.liability }} />
            </div>
            </div>
        </div>
    )
}

