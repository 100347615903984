import React, { useState } from "react"
// import './toggler.scss'
import style from './toggler.module.scss'

const btn = {
    s: 40,
    h: 10,
    w: 26,
}

const btnState = {
    open: `M ${(btn.s-btn.w)/2} ${btn.s-(btn.s-btn.h)/2} , ${btn.s/2} ${(btn.s-btn.h)/2}, ${btn.s-(btn.s-btn.w)/2} ${btn.s-(btn.s-btn.h)/2}`,
    close: `M ${(btn.s-btn.w)/2} ${(btn.s-btn.h)/2} , ${btn.s/2} ${btn.s-(btn.s-btn.h)/2}, ${btn.s-(btn.s-btn.w)/2} ${(btn.s-btn.h)/2}`
}

export default ({ title, children }) => {

    const [ menuIsOpen, setMenu ] = useState(false)

    const toggleDropdown = (e) => { setMenu(!menuIsOpen) }

    return(
        <div onClick={toggleDropdown} onKeyDown={toggleDropdown} className={style.toggler} role="button" tabIndex={0} >
            <div className={style.section__button}>
                {title ? <div>{title}</div> : false}
                {title ? <div>
                        <svg width={`${btn.s}px`} height={`${btn.s}px`}  viewBox={`0 0 ${btn.s} ${btn.s}`}>
                            <path 
                            d={menuIsOpen ? btnState.open : btnState.close}
                            stroke="red" 
                            // strokeWidth="1.5" 
                            strokeWidth="1" 
                            fill="none" />
                        </svg>
                    </div>
                : false}
            </div>
            <div className={`${style.section__content} ${menuIsOpen ? style.open : ""}`}>
                {children}
            </div>
        </div>
    )
}
