import React
// , { memo, useState } 
from "react"
import style from './imageHandler.module.scss'
import { exitTransition, entryTransition} from '../transition/transition'
import TransitionLink from 'gatsby-plugin-transition-link'

import DefinedLink from '../layout/link'
import Lazysizes from './lazysizes'
import Lazyload from './lazyLoad'

const stylePattern = {
  height: "300px",
  width: "200px",
  zIndex: 13,
}

const randNumArray = []

for(let i = 0; i < 100; i++) randNumArray.push(Math.random())

const addPatterns = (images, row) => {
  
  let random = []
  const totalPattern = Math.floor(images.length / 4)

  for (let i = 0; i < totalPattern; i++) {
    random.push(Math.floor(randNumArray[i+row] * images.length))
  }
    
  random.forEach((pos) => { images.splice(pos, 0, "pattern") })

  return images
}

class ImageHandler extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      images: addPatterns(this.props.images, this.props.row)
    }
  }

  render(){
    return(
      <div className={this.props.row === 0 ? style.imagesTopRow : style.imagesBottomRow} >
        {
          this.state.images.map((image, i) => {

            if(!image) return false

            if(image === "pattern"){
              return <Pattern i={i} key={i} slug={this.props.slug} />
            }
  
            const ratio = image.mediaDetails.width/image.mediaDetails.height;
            let className = ""
  
            if(ratio > 1.1)       className = style.landscape
            else if(ratio < 0.9)  className = style.portrait
            else                  className = style.square
            
            return(
              <div className={`${style.image} ${className}`} key={i} data-proportion={ratio}>
                {this.props.link ? 
                  <TransitionLink
                  /* <DefinedLink  */
                  exit={exitTransition}
                  entry={entryTransition}
                  to={`/projekte/${this.props.slug}/`}
                  state={{
                    imageId: image.key,
                    category: this.props.category
                  }}
                  aria-label={this.props.slug}
                  >
                    {/* LazyLoad */}
                    {/* { image, style, postTitle, size} */}
                    <Lazyload image={image} preload={false} size="500"/>
                  </TransitionLink>
                  :
                  <Lazysizes image={image} preload={false}/>
                }
              </div>
            )
          })
        }
        <style jsx="true">
          {`
            img.lazyloaded {
                opacity: 1;
                transition: 300ms;
                filter: none;
                transform: none;
            }
            img.lazypreload{
                opacity: 1;
                transition: 100ms;
            }
          `}
        </style>
      </div>
    )
  }
}

const Pattern = ({i, slug}) => (
  <div key={i} className={style.pattern} style={stylePattern}>
    {(typeof slug !== 'undefined' || slug) ? <DefinedLink 
    exit={exitTransition}
    entry={entryTransition}
    key={i}
    to={`/projekte/${slug}/`}
    state={{ imageId: false }}
    aria-label={slug}
    >
      <div />
    </DefinedLink>
    :
    <div />
    }
  </div>  
)

export default ImageHandler