import React from "react"
import style from './jobs.module.scss'


export default ({ title, jobs}) => (
  <div className={style.jobs}>
    <br/>
    <h3>{title}</h3>
    <br/>
    <div dangerouslySetInnerHTML={{__html: jobs }} className="paragraph"/>
  </div>
)