import React from "react"
import Toggler from './toggler'
import Jobs from './jobs'
import style from './about.module.scss'

export default ({ title, about_us, biography, jobs }) => {
    
    return(
        <Toggler title={title}>
            {
                about_us.map((item, i) => (
                    <div key={i} className={style.item}>
                        <div>{item.label}</div>
                        <div className={style.large}>{item.text}</div>
                    </div>
                ))
            }
            <div dangerouslySetInnerHTML={{__html: biography }} className="paragraph"/>
            <Jobs title="Offene Stellen" jobs={jobs} />
        </Toggler>
    )
}