import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

import shuffle from '../helpers/shuffle'

import Transition from '../components/transition/transition'
import BueroWrapper from '../components/pages/buero/buero';

export default (props) => {

  const buero = props.data.wpcontent.pageBy.buerofields;
  const [ employeesShuffled ] = useState(shuffle(buero.employees))

  return (
    <Layout className="page__buero">
      <SEO title="Buero" />
      <Transition>
        <main>
          <BueroWrapper buero={buero} employeesShuffled={employeesShuffled}/>
        </main>
      </Transition>
    </Layout>
  )
}

export const indexQuery = graphql`
  query bueroQuery {
    wpcontent{
      pageBy(uri: "buero") {
        id
        buerofields {
          images {
            id
            sourceUrl
            mediaDetails {
              width
              height
              sizes {
                width
                height
                sourceUrl
                
              }
            }
          }
          biography
          aboutUs {
            label
            text
          }
          jobs
          employees {
            firstName
            name
            position
            title
            email
          }
          alumni
          werkliste {
            mediaItemUrl 
          }
          imprint {
            label
            text
          }
          copyright
          liability
        }
      }
    }
  }
`