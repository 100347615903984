import React from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
// import { Link } from 'gatsby'

const DefinedLink = (props) => {

    // console.log("render defined link")
    // console.log("defined link",props)

    return(
        <TransitionLink {...props} />
    )
}

export default DefinedLink