import React from 'react'
// import 'lazysizes'
// import 'lazysizes/plugins/attrchange/ls.attrchange';
// import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// import 'lazysizes/plugins/respimg/ls.respimg.js';

import css from './lazysizes.module.scss'


const generateSrcSet = (sizes, image, minSize, maxSize, factor) => {
    let sources = []

    sizes.forEach((size) => {
        if(!size || !size.width || size.width === "400") return false
        if(maxSize && maxSize <= parseInt(size.width)) return false
        if(minSize && minSize >= parseInt(size.width)) return false
        let width = size.width
        if(factor) width = size.width*factor
        sources.push(`${size.sourceUrl} ${width}w`) 
    })

    if(image.sourceUrl) sources.push(image.sourceUrl)

    const srcset = sources.join(', ')

    return srcset
}

export default ({ image, preload, style, factor, postTitle, maxSize, minSize }) => {
    const sizes = image.mediaDetails.sizes.sort((a, b) => a.width - b.width )
    if(!sizes) return null

    const aspectRatio = sizes[sizes.length-1].width / sizes[sizes.length-1].height
    const padding = `${1/aspectRatio*100}%`
    const alt = image.alt_text ||  postTitle || ""

    // const src = sizes.find(item =>{
    //     // console.log(item.width)
    //     if(item.width === "512" || item.width === "768") return item
    // })

    let srcset = generateSrcSet(sizes, image, minSize, maxSize, factor)

    // console.log(src.sourceUrl)

    return (
        <div 
        className={css.image__container}
        style={{
            ...style, 
            paddingBottom: padding,
        }}>
            <div className={css.image__wrapper} >
                <img 
                    alt={alt}
                    srcSet={srcset}
                    src={image.sourceUrl}
                />
            </div>
        </div>
    )
}