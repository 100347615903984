import React, { useState } from "react"

const arrow = {
  default:  "M7 12L15 20L23 12",
  active:   "M7 14L15 24L23 14",
}

const line = {
  default:  "M15 5L15 20",
  active:   "M15 5L15 24",
}

const container = {
  default:  "M3 22V28H27V22",
  active:   "M6 24V28H24V24",
}
// { handleMouseEnter, handleMouseLeave}
const Werkliste = () => {

  const [ active, setActive ] = useState(false)

  const handleMouseEnter = () => {
    console.log("mouse enter")
    setActive(true)
  }
  const handleMouseLeave= () => {
    console.log("mouse leave")
    setActive(false)
  }

  return(
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} role="button" tabIndex={0} >
      <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g className="arrow">
          <path d={ active ? arrow.active : arrow.default } stroke="#FF0000" strokeWidth="1"/>
          <path d={ active ? line.active : line.default } stroke="#FF0000" strokeWidth="1" />
        </g>
        <path d={ active ? container.active : container.default } stroke="#FF0000" strokeWidth="1"/>
      </svg>
    </div>
  )
}

export default Werkliste