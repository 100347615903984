import React from 'react'
import css from './lazysizes.module.scss'

export default ({ image, style, size}) => {
    const sizes = image.mediaDetails.sizes
    if(!sizes) return null

    const aspectRatio = sizes[sizes.length-1].width / sizes[sizes.length-1].height
    const padding = `${1/aspectRatio*100}%`
    // const loading = preload ? "lazypreload" : ""
    const alt = image.alt_text ||  ""
    // console.log(sizes)
    const src = sizes.find(item =>{
        // console.log(item.width)
        if(item.width === size || item.width === "768") return true
        else return false
    })

    return (
        <div 
        className={css.image__container}
        style={{
            ...style, 
            paddingBottom: padding,
        }}>
            <div className={css.image__wrapper} >
                <img 
                    alt={alt}
                    // className={`lazyload ${loading}`}
                    src={src.sourceUrl}
                />
            </div>
        </div>
    )
}